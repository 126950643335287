import { gql } from '@apollo/client'

const GET_AUTHORS = gql`
  query {
    authors {
      data {
        id
        attributes {
          name
          role
          bio
          avatar {
            data {
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`
export default GET_AUTHORS
