import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Switch } from '@headlessui/react'
import MainLayout from 'layouts/MainLayout'
import { cx } from 'utils/helpers'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import SimpleLayout from '../../layouts/SimpleLayout'
import InputForm from 'components/forms/InputForm'
import TextareaForm from 'components/forms/TextareaForm'
import axios from 'axios'
import { validateEmail, validateMessage, validateName, validatePhoneNumber } from 'utils/validation'

function Contact() {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [agreed, setAgreed] = useState<boolean>(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [body, setBody] = useState('')
  const [country, setCountry] = useState('US')

  // const handleSubmit = async (event) => {
  //   event.preventDefault()

  //   const data = new FormData()
  //   data.append('First Name', firstName)
  //   data.append('Last Name', lastName)
  //   data.append('email', email)
  //   data.append('Country', country)
  //   data.append('Phone Number', phoneNumber)
  //   data.append('body', body)

  //   const url =
  //     'https://webhook.frontapp.com/forms/optimize_ad/SzgYgbQNvtn53iswxBHeMsuQ2kxelM9YYhm5e9GxlcI-KeZS61PayZUQHl5G3ao9FazXv9yfWPcZEEPPhNZDbS0McbcLTBqzS30O_eeMu3v2bQI'

  //   await fetch(url, {
  //     method: 'POST',
  //     body: data,
  //   })
  // }

  const onSubmit = async (values: any) => {
    const data = new FormData()
    data.append('First Name', values.firstName)
    data.append('Last Name', values.lastName)
    data.append('email', values.email)
    data.append('Country', 'US')
    data.append('Phone Number', values.phoneNumber.replace(/\D/g, ''))
    data.append('body', values.message)

    const url =
      'https://webhook.frontapp.com/forms/optimize_ad/SzgYgbQNvtn53iswxBHeMsuQ2kxelM9YYhm5e9GxlcI-KeZS61PayZUQHl5G3ao9FazXv9yfWPcZEEPPhNZDbS0McbcLTBqzS30O_eeMu3v2bQI'

    try {
      const res = await axios.post(url, data)
      console.log(res)
      setSubmitted(true)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <SimpleLayout title={'Contact Us | Penny Pincher News'}>
      <div className="relative isolate -mt-20 bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-gray-900/10 ring-1 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                  <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                If you have any questions, comments, or concerns please reach out to us and a member
                of our team will get back to you as soon as possible.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    30 N Gould St
                    <br />
                    Sheridan, WY 82801
                  </dd>
                </div>
                {/*<div className="flex gap-x-4">*/}
                {/*  <dt className="flex-none">*/}
                {/*    <span className="sr-only">Telephone</span>*/}
                {/*    <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />*/}
                {/*  </dt>*/}
                {/*  <dd>*/}
                {/*    <a className="hover:text-gray-900" href="tel:+1 (555) 234-5678">*/}
                {/*      +1 (555) 234-5678*/}
                {/*    </a>*/}
                {/*  </dd>*/}
                {/*</div>*/}
                {/*<div className="flex gap-x-4">*/}
                {/*  <dt className="flex-none">*/}
                {/*    <span className="sr-only">Email</span>*/}
                {/*    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />*/}
                {/*  </dt>*/}
                {/*  <dd>*/}
                {/*    <a className="hover:text-gray-900" href="mailto:hello@example.com">*/}
                {/*      hello@example.com*/}
                {/*    </a>*/}
                {/*  </dd>*/}
                {/*</div>*/}
              </dl>
            </div>
          </div>
          {submitted ? (
            <h2 className="px-6 pb-24 pt-20 text-center text-xl text-gray-900 sm:pb-32 lg:px-8 lg:py-48">
              We have received your inquiry and will get back to you soon!
            </h2>
          ) : (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form
                  className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
                  onSubmit={handleSubmit}
                >
                  <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                      <Field name="firstName" validate={validateName}>
                        {(props) => <InputForm label="First name" {...props} />}
                      </Field>
                      <Field name="lastName" validate={validateName}>
                        {(props) => <InputForm label="Last name" {...props} />}
                      </Field>
                      <Field name="email" validate={validateEmail}>
                        {(props) => (
                          <InputForm className="sm:col-span-2" label="Email" {...props} />
                        )}
                      </Field>
                      <Field name="phoneNumber" validate={validatePhoneNumber}>
                        {(props) => (
                          <InputForm
                            className="sm:col-span-2"
                            label="Phone number"
                            isPhone
                            {...props}
                          />
                        )}
                      </Field>
                      <Field name="message" validate={validateMessage}>
                        {(props) => (
                          <TextareaForm
                            className="sm:col-span-2"
                            label="Message"
                            rows={4}
                            {...props}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="mt-8 flex justify-end">
                      <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Send message
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          )}
        </div>
      </div>
    </SimpleLayout>
  )
}
export default Contact
