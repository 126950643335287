import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface ICategory {
  categories: Record<string, any>
}

const initialState: ICategory = {
  categories: {},
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<any>) => {
      state.categories = action.payload
    },
  },
})

export const { setCategories } = categorySlice.actions

export default categorySlice.reducer
