import { useId } from 'react'
import InputMask from 'react-input-mask'
import { cx } from 'utils/helpers'

interface InputFormProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: string
  isPhone?: boolean
  input: any
  meta: any
}

const InputForm: React.FC<InputFormProps> = ({
  className,
  label,
  isPhone = false,
  input,
  meta,
  ...props
}) => {
  const id = useId()

  return (
    <div className={cx(className)}>
      <label htmlFor={id} className="block text-sm font-semibold leading-6 text-gray-900">
        {label}
      </label>
      {!isPhone ? (
        <input
          {...input}
          className="mt-2.5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
          {...props}
        />
      ) : (
        <InputMask mask="(999) 999-9999" value={input.value} onChange={input.onChange}>
          {(inputProps) => (
            <input
              // {...input}
              {...inputProps}
              className="mt-2.5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
              {...props}
            />
          )}
        </InputMask>
      )}
      {meta.touched && meta.error && <p className="mt-1 text-xs text-red-500">{meta.error}</p>}
    </div>
  )
}

export default InputForm
