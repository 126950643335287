import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useApolloClient, useQuery } from '@apollo/client'
import MainLayout from 'layouts/MainLayout'
import SideFooter from 'layouts/SideFooter'
import AdPlacement300600 from 'components/AdPlacement300600'
import ArticleFeedVersion2 from 'components/ArticleFeedVersion2_Home'
import FeaturedPost from 'components/FeaturedPost'
import FeaturedThumbnails from 'components/FeaturedThumbnails'
import TrendingArticles from 'components/TrendingArticles'
import { GET_HOME_FEED, HOME_LOAD_MORE } from '../../graphql/homepageFeed'
import EmailSignUp from '../../components/EmailSignUp'

const Home = () => {
  const [articles, setArticles] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const client = useApolloClient()
  const { loading, error, data } = useQuery(GET_HOME_FEED)

  useEffect(() => {
    setArticles(
      data?.articles.data.filter(
               (article: any) =>
          data.homepage.data !==
          article.attributes.slug
      )
    )
    if (data?.articles.data.length < 20) {
      setHasMore(false)
    }
  }, [data])

  const onNextArticles = async (): Promise<void> => {
    if (!articles) return

    const { data } = await client.query({ query: HOME_LOAD_MORE(currentPage + 1) })

    const temp = articles

    temp.push(...data.articles.data)

    setArticles(temp)

    if (data.articles.data.length < 20) {
      setHasMore(false)
    } else {
      setCurrentPage((prev) => prev + 1)
    }
  }

  return (
    <MainLayout
      title="Penny Pincher News"
      description="Offering strategic financial advice, diverse money-saving tips, and a wealth of resources to help you maximize your savings and effectively manage your budget."
    >
      <div className="mx-auto max-w-7xl md:px-4">
        <div className="gap-8 md:flex">
          <div className="basis-3/4">
{/*             <FeaturedPost article={data?.homepage.data.attributes} /> */}
  <div className="inline border-l-[8px] border-purple-600 pl-2 text-lg font-bold">
    Trending Stories
  </div>
            <FeaturedThumbnails articles={articles?.slice(0, 3)} />
            <InfiniteScroll
              dataLength={articles?.length || 0}
              next={onNextArticles}
              hasMore={hasMore}
              loader={<p>Loading...</p>}
              endMessage={<></>}
            >
              <FeaturedThumbnails articles={articles?.slice(3, articles.length)} />
            </InfiniteScroll>
          </div>
          <div className="basis-1/4">
{/*             <TrendingArticles /> */}
            {/* <div className="mt-10">
              <EmailSignUp />
            </div> */}
            <div className="sticky top-32">
              {/*<AdPlacement300600 />*/}
              <SideFooter />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Home
