import Helmet from 'react-helmet'

interface Props {
  children?: React.ReactNode
  className?: string
  title?: string
}

const AdvertorialLayout: React.FC<Props> = ({ children, className = '', title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="sticky top-0 z-50">
        <div className="bg-white">
          <header className="shadow-md">
            <nav aria-label="Global">
              <div className="border-b">
                <div className="mx-auto flex max-w-7xl items-center justify-between p-3 md:p-3 ">
                  <div className="flex lg:flex-1">
                    <a
                      href="/"
                      className="-m-1.5 p-1.5 font-mono text-xl uppercase tracking-tight md:text-3xl"
                    >
                      <span className="font-bold">Penny</span>{' '}
                      <span className="-ml-2">Pincher</span>
                    </a>
                  </div>
                  <div className="hidden lg:flex lg:gap-x-12">Advertorial</div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <main className={`${className} mt-4 md:mt-10`}>{children}</main>
    </>
  )
}

export default AdvertorialLayout
