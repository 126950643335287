import { gql } from '@apollo/client'

const NAVIGATION = gql`
  query {
    global {
      data {
        attributes {
          navigation {
            links {
              label
              category {
                data {
                  id
                  attributes {
                    href
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NAVIGATION
