import React, { useState, useEffect } from 'react'
import AdvertorialLayout from '../../layouts/AdvertorialLayout'
import AdvertorialFooter from '../../layouts/AdvertorialFooter'

function CompareRatesDemo() {
  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)

  const handleReadMoreClick = () => {
    setIsReadMoreClicked(true)
  }

  return (
    <AdvertorialLayout>
      <div className="mx-auto max-w-3xl px-2">
        <div className="text-xl font-bold md:text-3xl">
          Drivers Are Swapping Their Insurance Company & Doing This Instead
        </div>
        <div className="text-lg">
          <p className="mt-4 font-bold text-red-700 md:mt-6">
            Do not pay your next auto insurance bill until you try this…
          </p>
          <p className="mt-4">
            There is a brand-new online system that most drivers don’t know about, and it was
            created to significantly reduce how much you pay for auto insurance. Here’s what we know
            so far. If you:
          </p>
          <ol className="ml-4 mt-4 list-disc">
            <li>Are currently insured</li>
            <li>Live in eligible zip code</li>
            <li>Drive less than 50 miles per day</li>
          </ol>
        </div>

        <div className="mt-8">
          <div className="result">
            <div className="mb-2 text-sm text-gray-800">Sponsored listings</div>

            <a
              className={`mt-3 flex cursor-pointer items-center rounded-md bg-primary-600
                 p-4 hover:bg-primary-700`}
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="h-8 fill-white"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
              <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                Affordable Auto Insurance Plans
              </div>
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-8 fill-white"
                >
                  <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                </svg>
              </div>
            </a>
            <a
              className={`mt-3 flex cursor-pointer items-center rounded-md bg-primary-600
                 p-4 hover:bg-primary-700`}
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="h-8 fill-white"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
              <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                Get Cheap Auto Insurance Rates in TX!
              </div>
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-8 fill-white"
                >
                  <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                </svg>
              </div>
            </a>
            <a
              className={`mt-3 flex cursor-pointer items-center rounded-md bg-primary-600
                 p-4 hover:bg-primary-700`}
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="h-8 fill-white"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
              <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                Auto Insurance As Low As $19/Mo in Texas
              </div>
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-8 fill-white"
                >
                  <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                </svg>
              </div>
            </a>
            <a
              className={`mt-3 flex cursor-pointer items-center rounded-md bg-primary-600
                 p-4 hover:bg-primary-700`}
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  className="h-8 fill-white"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
              <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                Smart Financial Auto Insurance Comparison
              </div>
              <div className="grow-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="h-8 fill-white"
                >
                  <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                </svg>
              </div>
            </a>

            {!isReadMoreClicked && (
              <div
                className="readmore mt-10 cursor-pointer text-center font-medium text-primary-600"
                onClick={handleReadMoreClick}
              >
                Read More...
              </div>
            )}
            <div
              className={`extracontent mt-10 text-lg leading-7 ${
                isReadMoreClicked ? '' : 'hidden'
              }`}
            >
              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Maintain a Clean Driving Record
              </h2>
              <p>
                A history of accidents, traffic violations, and reckless driving can significantly
                increase your insurance premiums. By driving safely, obeying traffic rules, and
                avoiding accidents, you can demonstrate to insurance companies that you are a
                low-risk driver, leading to reduced rates. Periodically check your driving record to
                ensure all information is accurate and contest any discrepancies if necessary.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Shop Around and Compare Quotes
              </h2>
              <p>
                Different insurance companies offer different rates based on their own criteria and
                risk assessments. By shopping around and getting quotes from multiple insurers, you
                can compare and choose the most affordable option. Remember to compare similar
                policies in terms of coverage, deductibles, and limits to get a true sense of which
                offers the best value.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Bundle Insurance Policies
              </h2>
              <p>
                Many insurance companies offer discounts when you bundle multiple policies, such as
                auto and home insurance, with the same provider. This not only streamlines your
                billing and policy management but also can lead to significant savings. If you have
                multiple types of insurance needs, inquire about bundling options and discounts.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Increase Your Deductible
              </h2>
              <p>
                Your deductible is the amount you pay out of pocket before your insurance coverage
                kicks in. By agreeing to a higher deductible, you reduce the insurer's risk, which
                often results in a lower premium. However, ensure that you're comfortable with and
                capable of paying the higher deductible in the event of a claim.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Take Advantage of Discounts
              </h2>
              <p>
                Many insurance companies offer various discounts to drivers for factors like low
                mileage, good student achievements, completion of a defensive driving course, having
                safety features in the car, or being a member of certain associations or
                professions. Always inquire about potential discounts and ensure that you're getting
                all the reductions you qualify for.
              </p>
            </div>
          </div>
        </div>
      </div>
      <AdvertorialFooter />
    </AdvertorialLayout>
  )
}

export default CompareRatesDemo
