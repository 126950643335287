import Helmet from 'react-helmet'
import Header from './Header'

interface Props {
  children?: React.ReactNode
  className?: string
  title?: string
}

const MainLayout: React.FC<Props> = ({ children, className = '', title, description }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="">
        <Header />
        <main className={`${className} mt-4 md:mt-10`}>{children}</main>
      </div>
    </>
  )
}

export default MainLayout
