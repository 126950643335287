import { gql } from '@apollo/client'

const GET_CATEGORY = (href: string | undefined, pageNumber: number) => gql`
  query {
    categories(filters: { href: { eq: "${href}" } }) {
      data {
        attributes {
          name
          category_color
          headline
          featured_article {
            data {
              id
              attributes {
                title
                preview_text
                slug
                primary_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          featured_article_cta
          href
          articles (sort: "id:desc", pagination: {pageSize: 20, page: ${pageNumber}}) {
            data {
              id
              attributes {
                title
                slug
                sub_category {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                primary_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_CATEGORY
