import axios from 'axios'
import { EMAIL_SIGN_UP_URL } from 'config'

export const apiEmailSignUp = async (email: string): Promise<any> => {
  try {
    const { data } = await axios.post(EMAIL_SIGN_UP_URL || '', { record: { emailAddress: email } })
    return data
  } catch (err) {
    throw err
  }
}
