import { gql } from '@apollo/client'

const GET_ADVERTORIAL = (slug: string | undefined) => gql`
  query {
    advertorials(filters: { slug: { eq: "${slug}" } }) {
    data {
      attributes {
        title
        slug
        primary_image {
          data {
            attributes {
              formats
            }
          }
        }
        primary_content {
          ... on ComponentAdvertorialContent {
            id
            content
            offer_url
            voluum_url
          }
          ... on ComponentAdvertorialAdvertorialButton {
            id
            text_above_buttons
            label
            columns
            color
            offer_url
            voluum_url
          }
        }
        offer {
          ... on ComponentAdvertorialContentBlock {
            id
            headline
            content
            offer_image {
              data {
                attributes {
                  url
                }
              }
            }
            offer_url
            voluum_url
          }
          ... on ComponentAdvertorialAdvertorialButton {
            id
            text_above_buttons
            label
            columns
            color
            offer_url
            voluum_url
          }
        }
        sources {
          label
          href
        }
      }
    }
    }
  }
`

export default GET_ADVERTORIAL
