import AdvertorialLayout from '../../layouts/AdvertorialLayout'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import GET_ADVERTORIAL from '../../graphql/advertorial'
import { InfinitySpin } from 'react-loader-spinner'
import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import parse, { domToReact, htmlToDOM } from 'html-react-parser'
import AdvertorialFooter from '../../layouts/AdvertorialFooter'

const Advertorial = () => {
  const params = new URLSearchParams(window.location.search)
  const { slug } = useParams()
  const { loading, error, data } = useQuery(GET_ADVERTORIAL(slug))

  if (error) return <p>Error :(</p>

  const advertorial = data?.advertorials.data[0].attributes
  const primary_content = data?.advertorials.data[0].attributes.primary_content
  const offers = data?.advertorials.data[0].attributes.offer

  const [date, setDate] = useState('')

  useEffect(() => {
    const threeDaysAgo = moment().subtract(3, 'day').format('MMMM DD, YYYY')
    setDate(threeDaysAgo)
  }, [])

  const options_primary = {
    replace: ({ attribs, children }) => {
      if (!attribs) return

      primary_content.forEach((content) => {
        if (content.offer_url && attribs.href) {
          attribs.href = attribs.href = `${content.offer_url}?${params.toString()}`
          attribs.class = 'cta-link'
          return <a {...attribs}>{domToReact(children, options_primary)}</a>
        }
      })
    },
  }

  const options_offer = {
    replace: ({ attribs, children }) => {
      if (!attribs) return

      offers.forEach((offer) => {
        if (offer.offer_url && attribs.href) {
          attribs.href = `${offer.offer_url}?${params.toString()}`
          attribs.class = 'cta-link'
          return <a {...attribs}>{domToReact(children, options_offer)}</a>
        }
      })
    },
  }

  return (
    <AdvertorialLayout
      title={
        advertorial && advertorial.title
          ? advertorial.title + ' | Penny Pincher News'
          : 'Loading... | Penny Pincher News'
      }
    >
      {loading ? (
        <div className="flex justify-center">
          <InfinitySpin color="#5850E0" />
        </div>
      ) : (
        <div>
          <div className="mx-auto max-w-3xl px-2">
            <div className="mt-2 text-xl font-bold md:mt-4 md:text-4xl">{advertorial.title}</div>
            <img
              className="mx-auto mt-4 w-full rounded-lg"
              src={advertorial.primary_image.data.attributes.formats.medium.url}
            />
            <div className="mt-4 text-xs text-gray-500 md:text-sm"></div>
            <div className="mt-6 text-sm text-gray-500 md:text-base">Last Updated: {date}</div>
            <div className="mt-4 w-36 border-b-[6px] border-gray-600"></div>

            {primary_content.map((content) => (
              <div key={content.id}>
                {content.content && (
                  <div className="article-body prose max-w-none prose-p:text-lg prose-p:leading-8">
                    {parse(content.content, options_primary)}
                  </div>
                )}
                {content.text_above_buttons && (
                  <div className="mb-1 mt-4 text-center text-lg font-bold md:text-xl">
                    {content.text_above_buttons}
                  </div>
                )}
                {content.label && (
                  <div className={`md:grid-cols-${content.columns} mt-0 grid w-full gap-x-2`}>
                    {content.label.split(',').map((item, index) => {
                      return (
                        <a
                          key={index}
                          className={`adv-btn adv-btn-${content.color}`}
                          href={`${content.offer_url}?${params.toString()}`}
                          target="_blank"
                        >
                          {item.trim()}
                        </a>
                      )
                    })}
                  </div>
                )}
              </div>
            ))}

            {offers.map((offer) => (
              <div key={offer.id}>
                {offer.headline && (
                  <div className="mt-10 border-t border-t-gray-400 pt-16 text-xl font-bold md:mt-16 md:text-4xl">
                    <div className="leading-tight">{offer.headline}</div>
                  </div>
                )}
                {offer.offer_image &&
                  offer.offer_image.data &&
                  offer.offer_image.data.attributes &&
                  offer.offer_image.data.attributes.url && (
                    <img
                      className="mx-auto mt-4 w-full rounded-lg"
                      src={offer.offer_image.data.attributes.url}
                    />
                  )}
                {offer.content && (
                  <div className="article-body prose mb-8 max-w-none prose-p:text-lg prose-p:leading-8">
                    {parse(offer.content, options_offer)}
                  </div>
                )}

                {offer.text_above_buttons && (
                  <div className="mb-1 mt-4 text-center text-lg font-bold md:text-xl">
                    {offer.text_above_buttons}
                  </div>
                )}

                {offer.label && (
                  <div className={`md:grid-cols-${offer.columns} mt-0 grid w-full gap-x-2`}>
                    {offer.label.split(',').map((item, index) => {
                      return (
                        <a
                          key={index}
                          className={`adv-btn adv-btn-${offer.color}`}
                          href={`${offer.offer_url}?${params.toString()}`}
                          target="_blank"
                        >
                          {item.trim()}
                        </a>
                      )
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
          <AdvertorialFooter advertorial={advertorial} />
        </div>
      )}
    </AdvertorialLayout>
  )
}
export default Advertorial
