import { useQuery } from '@apollo/client'
import NAVIGATION from '../graphql/navigation'
import { Link, useLocation } from 'react-router-dom'
import { cx } from 'utils/helpers'

export const NavigationDesktop = () => {
  const { loading, error, data } = useQuery(NAVIGATION)
  const { pathname } = useLocation()

  if (loading)
    return (
      <div
        role="status"
        className="mx-auto hidden max-w-7xl animate-pulse justify-around py-4 lg:flex"
      >
        <div className="h-2 w-24 rounded-full bg-gray-200 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-300 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-200 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-300 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-200 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-300 py-2"></div>
        <div className="h-2 w-24 rounded-full bg-gray-200 py-2"></div>
      </div>
    )
  if (error) return <p>Error :(</p>

  const links = data.global.data.attributes.navigation.links

  return (<div></div>
    // <div className="mx-auto hidden max-w-7xl justify-around lg:flex">
    //   {links.map((link: Record<any, any>, index: number) => (
    //     <Link
    //       key={index}
    //       to={link.category.data.attributes.href}
    //       className={cx(
    //         pathname.includes(link.category.data.attributes.href)
    //           ? 'border-b-2 border-b-indigo-600 text-indigo-600'
    //           : 'text-gray-900',
    //         'py-3 text-base font-bold leading-6 hover:text-indigo-600'
    //       )}
    //     >
    //       {link.label}
    //     </Link>
    //   ))}
    // </div>
  )
}

export const NavigationMobile = () => {
  const { loading, error, data } = useQuery(NAVIGATION)

  if (loading) return <p>Loading</p>
  if (error) return <p>Error :(</p>

  const links = data.global.data.attributes.navigation.links

  return (<div></div>
    // <>
    //   {links.map((link: Record<any, any>, index: number) => (
    //     <Link
    //       key={index}
    //       to={link.category.data.attributes.href}
    //       className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
    //     >
    //       {link.label}
    //     </Link>
    //   ))}
    // </>
  )
}
