import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { cx } from 'utils/helpers'

interface Props {
  featuredArticle: any
  articles: any[]
  from?: string
  textColor?: string
}

const ArticleFeedVersion2: React.FC<Props> = ({ featuredArticle, articles, from, textColor }) => {
  return (
    <div className="px-2 md:px-0">
      <div className="text-md mb-4 mt-6 border-l-[8px] border-orange-600 pl-2 font-bold md:text-lg">
        {from ? `Latest From ${from}` : 'Latest Articles'}
      </div>
      <div className="grid grid-cols-2 gap-4 md:gap-8">
        {articles
          ? articles?.map((item, index) => (
              <Fragment key={index}>
                <Link
                  to={item.attributes.slug}
                  className={
                    featuredArticle
                      ? featuredArticle.featured_article.data.attributes.slug ===
                        item.attributes.slug
                        ? 'hidden'
                        : ''
                      : ''
                  }
                >
                  <div className="article-container">
                    <img
                      className="mb-2 h-32 w-full rounded-lg object-cover md:h-52"
                      src={item.attributes.primary_image.data?.attributes.url}
                    />
                    <div
                      className={cx(
                        'inline text-xs font-black md:text-sm',
                        textColor ? textColor : 'text-blue-500'
                      )}
                    >
                      {item.category}
                    </div>
                    <div className="headline line-clamp-3 text-sm font-semibold md:mt-1 md:line-clamp-3 md:text-lg md:tracking-tight">
                      {item.attributes.title}
                    </div>
                  </div>
                </Link>
                {/*{index % 4 === 1 && (*/}
                {/*  <div className="col-span-2 w-full px-2 md:mb-6 md:mt-6 md:px-0">*/}
                {/*    <img className="mx-auto" src="https://placehold.co/728x90?text=Ad+Placement" />*/}
                {/*  </div>*/}
                {/*)}*/}
              </Fragment>
            ))
          : Array(4)
              .fill(0)
              .map((_, ind) => (
                <div role="status" className="grid animate-pulse grid-cols-1 gap-4" key={ind}>
                  <div
                    className="flex items-center justify-center rounded bg-gray-300 dark:bg-gray-700"
                    style={{ aspectRatio: 16 / 9 }}
                  >
                    <svg
                      className="h-12 w-12 text-gray-200"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 640 512"
                    >
                      <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                    </svg>
                  </div>
                  <div className="flex w-full flex-col items-center justify-center">
                    <div className="mb-4 h-3 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
                  </div>
                  <span className="sr-only">Loading...</span>
                </div>
              ))}
      </div>
    </div>
  )
}

export default ArticleFeedVersion2
