import { useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { InfinitySpin } from 'react-loader-spinner'
import MainLayout from 'layouts/MainLayout'
import AdPlacement300600 from 'components/AdPlacement300600'
import RelatedArticles from 'components/RelatedArticles'
import GET_ARTICLE from '../../graphql/article_slug'
import { cx } from '../../utils/helpers'
import {
  FacebookIcon,
  FacebookShareButton,
  EmailIcon,
  EmailShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import Footer from '../../layouts/Footer'

const Article = () => {
  const { slug } = useParams()
  const { loading, error, data } = useQuery(GET_ARTICLE(slug))

  if (error) return <p>Error :(</p>

  const article = data?.articles.data[0].attributes
  const location = useLocation()
  const fullURL = window.location.href + '?utm_source=shareIcon'

  return (
    <>
      <MainLayout
        title={
          article && article.title
            ? article.title + ' | Penny Pincher News'
            : 'Loading... | Penny Pincher News'
        }
        description={article && article.preview_text ? article.preview_text : 'Penny Pincher News'}
      >
        {loading ? (
          <div className="flex justify-center">
            <InfinitySpin color="#5850E0" />
          </div>
        ) : (
          <div className="relative mx-auto max-w-7xl px-4">
            {/*<div className="mb-6 w-full">*/}
            {/*  <img src="https://placehold.co/1400x250?text=Ad+Placement" />*/}
            {/*</div>*/}
            <div className="gap-8 lg:flex">
              <div className="basis-3/4" itemScope itemType="https://schema.org/NewsArticle">
                <div
                  className={cx(
                    'inline text-xs font-black md:text-base',
                    article.category.data.attributes.category_color
                      ? `text-${article.category.data.attributes.category_color}`
                      : 'text-blue-500'
                  )}
                >
                  {article.category.data.attributes.name}
                </div>
                {/*<div className="inline text-xs md:text-sm ml-2 text-gray-500 tracking-tight"></div>*/}
                <div className="mt-2 text-xl font-bold md:mt-4 md:text-3xl" itemProp="headline">
                  {article.title}
                </div>
                <img
                  className="mt-4 h-[250px] w-full rounded-lg object-cover md:h-[500px]"
                  itemProp="image"
                  src={article.primary_image.data?.attributes.url}
                />
                <div className="mt-4 text-xs text-gray-500 md:text-sm"></div>

                <div className="relative mt-8 flex items-center gap-x-4">
                  <img
                    src={article.author.data?.attributes.avatar.data?.attributes.url}
                    alt=""
                    className="h-10 w-10 rounded-full bg-gray-50 object-cover"
                  />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <span className="absolute inset-0" />
                      {article.author.data?.attributes.name}
                    </p>
                    <p className="text-gray-600">{article.author.data?.attributes.role}</p>
                  </div>
                </div>

                <div
                  className="mt-2 text-sm text-gray-500 md:mt-6 md:text-base"
                  itemProp="datePublished"
                >
                  <span className="mr-1">Published on:</span>
                  {new Date(article.publishedAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </div>
                <div className="my-4 2xl:absolute 2xl:sticky 2xl:top-[140px] 2xl:float-left 2xl:-ml-20 2xl:-mt-1 2xl:h-20">
                  <div className="2xl:grid 2xl:grid-cols-1 2xl:gap-4">
                    <div className="pb-2 text-xs font-bold uppercase text-gray-600 sm:mr-2 sm:inline-block sm:pt-3 sm:align-top sm:text-sm 2xl:-ml-4 2xl:pb-0">
                      Share on:
                    </div>
                    <FacebookShareButton url={fullURL} quote={article.title}>
                      <FacebookIcon round={true} size={42} className="share-icon" />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={fullURL}
                      title={article.title}
                      className="ml-2 2xl:ml-0"
                    >
                      <TwitterIcon round={true} size={42} className="share-icon" />
                    </TwitterShareButton>

                    <PinterestShareButton
                      url={fullURL}
                      media={article.primary_image.data?.attributes.url}
                      description={article.title}
                      className="ml-2 2xl:ml-0"
                    >
                      <PinterestIcon round={true} size={42} className="share-icon" />
                    </PinterestShareButton>

                    <WhatsappShareButton
                      url={fullURL}
                      title={article.title}
                      className="ml-2 2xl:ml-0"
                    >
                      <WhatsappIcon round={true} size={42} className="share-icon" />
                    </WhatsappShareButton>

                    <EmailShareButton
                      url={fullURL}
                      subject={article.title}
                      body={'Check out this article on PennyPincher.com!'}
                      separator={'\n \n'}
                      className="ml-2 2xl:ml-0"
                    >
                      <EmailIcon round={true} size={42} className="share-icon" />
                    </EmailShareButton>
                  </div>
                </div>
                <div className="my-4 w-36 border-b-[8px] border-gray-800 md:w-56"></div>
                <div
                  itemProp="articleBody"
                  className="article-body prose max-w-none"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />

                {/*<img className="mx-auto my-8" src="https://placehold.co/728x90?text=Ad+Placement" />*/}

                <RelatedArticles
                  article={article}
                  stories={data.articles.data[0].attributes.category.data.attributes}
                />
              </div>

              <div className="basis-1/4">
                <div className="sticky top-28">{/*<AdPlacement300600 />*/}</div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
      <Footer />
    </>
  )
}

export default Article
