import MainLayout from '../../layouts/MainLayout'
import Footer from '../../layouts/Footer'
import { useQuery } from '@apollo/client'
import GET_AUTHORS from '../../graphql/authors'
import { Link } from 'react-router-dom'

const Aboutus = () => {
  const { loading, error, data } = useQuery(GET_AUTHORS)
  const authors = data?.authors.data
  return (
    <div>
      <MainLayout title="About Us | Penny Pincher News">
        <div className="mx-auto mt-10 max-w-7xl px-4">
          <div>
            <h1 className="text-center text-xl font-bold tracking-tight text-gray-900 sm:text-4xl md:mt-20">
              About Us
            </h1>
            <p className="mx-auto mt-6 max-w-5xl border-l-4 border-gray-400 bg-gray-100 p-3 text-sm font-medium italic leading-6 md:mt-10 md:p-5 md:text-xl md:leading-8 md:leading-9">
              “The greatness of a man is not in how much wealth he acquires, but in his integrity
              and his ability to affect those around him positively.” -Bob Marley
            </p>
            <p className="mt-6 text-base leading-8 md:mt-8 md:text-lg md:leading-9">
              In a time when it can be truly daunting to find real data that is not overcomplicated
              or downright misleading, we make a steadfast commitment to delivering straightforward
              access to uncomplicated information.
            </p>
            <p className="mt-4 text-base leading-8 md:text-lg md:leading-9">
              The information you'll find on PennyPincher.com is produced by our expert research
              teams who delve deep into various areas to produce concise, easy- to-read, and
              prioritized articles aimed at empowering you with the knowledge needed to make
              informed decisions. These decisions can enrich your life from before your first
              employment through financial evolution and onto retirement.
            </p>

            <h2 className="mt-10 text-xl font-medium md:text-2xl">Our Content</h2>
            <p className="mt-4 text-base leading-8 md:text-lg md:leading-9">
              At PennyPincher.com, we thoroughly explore the too-good-to-be-true deals, top-rated
              review sources, studies, and research releases to discover expert and dependable
              foundations for information, technology, finance, and development. We strive to curate
              and present this information in a way that is easy to understand, while continuously
              expanding our knowledge base. Our knowledge base and article layout are specifically
              designed to help our readers constantly grow, even when learning in a hurry. Whether
              you're facing the challenges of burdensome debt or choosing a new auto insurance
              carrier, we work every day to provide dependable and accurate information that you can
              act on to get closer to realizing your personal goals and dreams.
            </p>
            <p className="mt-4 text-base leading-8 md:text-lg md:leading-9">
              Every member of our team is dedicated to studying the most up-to-the- minute and
              relevant information, trends, and technological innovations. This allow us to build a
              consumer experience that is truly helpful, thereby ensuring that you can rely on us to
              consistently deliver exceptional and trustworthy content.
            </p>

            <h2 className="mt-10 text-xl font-medium md:text-2xl">Contact Us</h2>
            <p className="mt-4 text-base leading-8 md:text-lg md:leading-9">
              Do you have any questions or comments you would like to share? We are open to all
              constructive feedback that can help us learn to better assist/serve our readers.
              Please feel free to{' '}
              <Link to="/contact" className="cursor-pointer text-indigo-600 hover:underline">
                reach out
              </Link>{' '}
              with any broad or specific topics you would like to see more of!
            </p>
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="mt-10 text-xl font-bold tracking-tight text-gray-900 sm:text-4xl md:mt-20 md:text-3xl">
              Meet our team
            </h2>
            <p className="mt-2 text-base leading-7 text-gray-600 md:mt-6 md:text-lg md:leading-8">
              We’re a dynamic group of individuals who are passionate about what we do and dedicated
              to delivering the best results for our readers.
            </p>
          </div>
          <div>
            <ul role="list" className="grid-cols-3 gap-6 md:grid">
              {authors ? (
                authors.map((author) => (
                  <li key={author.id} className="mt-10 md:flex">
                    <img
                      className="h-16 w-16 rounded-full object-cover md:h-20 md:w-20"
                      src={author.attributes.avatar.data.attributes.formats.small.url}
                      alt=""
                    />
                    <div className="mt-4 md:ml-4">
                      <h3 className="font-medium">{author.attributes.name}</h3>
                      <p className="text-sm leading-7 text-gray-600">{author.attributes.role}</p>
                      <p className="mt-2 text-base leading-7 text-gray-800">
                        {author.attributes.bio}
                      </p>
                    </div>
                  </li>
                ))
              ) : (
                <li className="mt-10 md:flex">
                  <div
                    role="status"
                    className="h-16 w-16 animate-pulse rounded-full bg-gray-200 md:h-20 md:w-20"
                  ></div>
                  <div className="mt-4 md:ml-4">
                    <span className="sr-only">Loading...</span>
                    <h3 className="h-6 w-36 animate-pulse bg-gray-200"></h3>
                    <p className="mt-2 h-4 w-24 animate-pulse bg-gray-100"></p>
                    <p className="mt-2 h-4 w-72 animate-pulse bg-gray-200"></p>
                    <p className="mt-2 h-4 w-72 animate-pulse bg-gray-200"></p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </MainLayout>
      <Footer />
    </div>
  )
}
export default Aboutus
