import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from 'pages/Home'
import Article from 'pages/Article'
import Category from 'pages/Category'
import Contact from 'pages/Contact'
import Privacy from 'pages/Privacy/Privacy'
import Terms from 'pages/Privacy/Terms'
import Advertorial from 'pages/Advertorial'
import adsTxt from 'bundle-text:../ads.txt'
import Aboutus from '../pages/Aboutus/Aboutus'
import DoNotSell from '../pages/Privacy/DoNotSell'
import CompareRates from '../pages/AutoInsurance/CompareRates'
import CompareRates2 from '../pages/AutoInsurance/CompareRates2'
import CompareRatesDemo from '../pages/AutoInsurance/CompareRatesDemo'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="contact">
        <Route index element={<Contact />} />
      </Route>
      <Route path="about-us">
        <Route index element={<Aboutus />} />
      </Route>
      <Route path="privacy">
        <Route index element={<Privacy />} />
      </Route>
      <Route path="terms">
        <Route index element={<Terms />} />
      </Route>
      <Route path="do-not-sell">
        <Route index element={<DoNotSell />} />
      </Route>
      <Route path="adv">
        <Route path=":slug" element={<Advertorial />} />
      </Route>
      <Route path="ads.txt">
        <Route index element={<p>{adsTxt}</p>} />
      </Route>
      <Route path=":category">
        <Route index element={<Category />} />
        <Route path=":slug" element={<Article />} />
      </Route>
      <Route path="auto-insurance">
        <Route path="compare-rates" element={<CompareRates />} />
        <Route path="compare-rates-2" element={<CompareRates2 />} />
        <Route path="compare-rates-demo" element={<CompareRatesDemo />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
