import { Link } from 'react-router-dom'

const SideFooter = () => {
  return (
    <div className="mt-10 border-t border-gray-300 pt-4 text-center text-sm tracking-tight text-gray-400">
      <div className="flex flex-wrap justify-center gap-2 text-center">
        <Link to="/about-us" className="px-2">
          About Us
        </Link>
        <Link to="/contact" className="px-2">
          Contact
        </Link>
        <Link to="/terms" className="px-2">
          Terms of Service
        </Link>
        <Link to="/privacy" className="px-2">
          Privacy Policy
        </Link>
        <Link to="/do-not-sell" className="px-2">
          Do Not Sell
        </Link>
        {/*<Link to="/do-not-sell" className="px-2">*/}
        {/*  Manage Cookies*/}
        {/*</Link>*/}
      </div>

      <div className="mt-6">2024 Penny Pincher. All rights reserved.</div>
    </div>
  )
}

export default SideFooter
