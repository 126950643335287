import { Link } from 'react-router-dom'
import PPlogoLight from 'images/pp-logo-light.svg'

const Footer = () => {
  return (
    <div className="mt-20 w-full bg-gray-900 py-10">
      <div className="mx-auto max-w-7xl px-2">
        <div className="mb-6 grid-cols-3 md:grid">
          <div className="col-span-2 text-center md:text-left">
            <a
              href="/"
              className="font-mono text-sm uppercase tracking-tight text-white md:text-xl"
            >
              {/*<span className="font-bold">Penny</span> <span className="-ml-2">Pincher</span>*/}
              <img src={PPlogoLight} className="mx-auto w-32 md:mx-0 md:w-44" />
            </a>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-4 place-items-center text-center text-sm text-gray-200 md:mt-0">
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
