import { gql } from '@apollo/client'

export const GET_HOME_FEED = gql`
  query {
    homepage {
      data {
        attributes {
          featured_article {
            data {
              attributes {
                title
                slug
                preview_text
                category {
                  data {
                    id
                    attributes {
                      name
                      href
                    }
                  }
                }
                primary_image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          featured_article_cta
        }
      }
    }
    articles(sort: "id:desc", pagination: { pageSize: 20 }) {
      data {
        id
        attributes {
          slug
          title
          category {
            data {
              id
              attributes {
                name
                href
                category_color
              }
            }
          }
          primary_image {
            data {
              attributes {
                name
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`

export const HOME_LOAD_MORE = (pageNumber: number) => gql`
  query {
    articles(sort: "id:desc", pagination: { pageSize: 20, page: ${pageNumber} }) {
      data {
        id
        attributes {
          slug
          title
          category {
            data {
              id
              attributes {
                name
                href
                category_color
              }
            }
          }
          primary_image {
            data {
              attributes {
                name
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`
