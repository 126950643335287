import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavigationDesktop, NavigationMobile } from './Navigation'
import PPlogo from 'images/pp-logo.svg'

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const buttonStyle = location.pathname === '/' ? { display: 'none' } : {}

  return (
    <div className="sticky top-0 z-50">
      <div className="bg-white">
        <header className="shadow-md">
          <nav aria-label="Global">
            <div className="border-b">
              <div className="mx-auto flex max-w-7xl items-center justify-between p-3 md:p-3 ">
                <div className="flex lg:flex-1">
                  <ChevronLeftIcon
                    style={buttonStyle}
                    onClick={() => navigate(-1)}
                    className="mr-2 h-7 w-7 md:hidden"
                  />
                  <a
                    href="/"
                    className="-m-1.5 p-1.5 font-mono text-xl uppercase tracking-tight md:text-3xl"
                  >
                    {/*<span className="font-bold">Penny</span> <span className="-ml-2">Pincher</span>*/}
                    <img src={PPlogo} className="w-40 md:w-60" />
                  </a>
                </div>
                <div className="flex lg:hidden">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(true)}
                  >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12"></div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                  {/*<a href="#" className="text-sm font-semibold leading-6 text-gray-900">*/}
                  {/*  Subscribe <span aria-hidden="true">&rarr;</span>*/}
                  {/*</a>*/}
                </div>
              </div>
            </div>
            <div>
              <NavigationDesktop />
            </div>
          </nav>
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-gray-900/10 sm:ring-1">
              <div className="flex items-center justify-between">
                <a
                  href="/"
                  className="-m-1.5 p-1.5 font-mono text-xl uppercase tracking-tight md:text-3xl"
                >
                  <img src={PPlogo} className="w-44 md:w-60" />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-1 py-6">
                    <NavigationMobile />
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
    </div>
  )
}

export default Header
