import MainLayout from 'layouts/MainLayout'

const Terms = () => {
  window.scrollTo(0, 0)

  return (
    <MainLayout title={'Terms of Use | Penny Pincher News'}>
      <div className="container mx-auto px-8">
        <div className="prose mx-auto max-w-7xl">
          <h1>Terms of Use</h1>
          <p>Last updated: May 29, 2023</p>
          <p>
            Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the
            Optimize Ventures website (the "Service") operated by Optimize Ventures Inc. ("us",
            "we", or "our").
          </p>
          <p>
            Your access to and use of the Service is conditioned upon your acceptance of and
            compliance with these Terms without modification. These Terms apply to all visitors,
            users and others who wish to access or use the Service.
          </p>
          <p>
            By accessing or using the Service you agree to be bound by these Terms, whether or not
            you have read them. If you disagree with any part of the terms then you do not have
            permission to access the Service.
          </p>
          <h3>Purchases</h3>
          <p>
            If you wish to purchase any data made available through the Service ("Purchase"), you
            may be asked to supply certain information relevant to your Purchase including, without
            limitation, your credit card number, the expiration date of your credit card, your
            billing address, and your shipping information.
          </p>{' '}
          <p>
            You represent and warrant that: (i) you have the legal right to use any credit card(s)
            or other payment method(s) in connection with any Purchase; and that (ii) the
            information you supply to us is true, correct and complete.
          </p>{' '}
          <p>
            The service may employ the use of third party services for the purpose of facilitating
            payment and the completion of Purchases. By submitting your information, you grant us
            the right to provide the information to these third parties subject to our Privacy
            Policy.
          </p>{' '}
          <p>
            You acknowledge and agree that Optimize Ventures does not operate or control the
            internet or mobile networks and that (i) viruses, worms, Trojan horses or other
            undesirable data or software, or (ii) unauthorized users may attempt to obtain access to
            and damage your data, websites, hardware or networks. Service shall not be responsible
            for such activities.
          </p>{' '}
          <p>
            We reserve the right to refuse or cancel your order at any time for reasons including
            but not limited to: product or service availability, errors in the description or price
            of the product or service, error in your order or other reasons.
          </p>{' '}
          <p>
            All data is sold "as is," "where is," and "with all faults," and, client shall have no
            right to request a refund. The Service will allow for a credit to your Optimize Ventures
            account of up to 10% of the cost for data you have purchased that is proven to be a
            disconnected phone number or a phone number that is not in service.
          </p>{' '}
          <p>
            We reserve the right to refuse or cancel your order if fraud or an unauthorized or
            illegal transaction is suspected.
          </p>
          <h3>Availability, Errors and Inaccuracies</h3>
          <p>
            We are constantly updating product and service offerings on the Service. We may
            experience delays in updating information on the Service and in our advertising on other
            web sites. The information found on the Service may contain errors or inaccuracies and
            may not be complete or current. Products or services may be mispriced, described
            inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or
            completeness of any information found on the Service.
          </p>
          <p>
            We therefore reserve the right to change or update information and to correct errors,
            inaccuracies, or omissions at any time without prior notice.
          </p>
          <h3>Contests, Sweepstakes and Promotions</h3>
          <p>
            Any contests, sweepstakes or other promotions (collectively, "Promotions") made
            available through the Service may be governed by rules that are separate from these
            Terms Conditions. If you participate in any Promotions, please review the applicable
            rules as well as our Privacy Policy. If the rules for a Promotion conflict with these
            Terms of Use, the Promotion rules will apply.
          </p>
          <h3>Accounts</h3>
          <p>
            When you create an account with us, you guarantee that you are above the age of 18, and
            that the information you provide to us is accurate, complete, and current at all times.
            Inaccurate, incomplete, or obsolete information may result in the immediate termination
            of your account on the Service.
          </p>{' '}
          <p>
            You are responsible for maintaining the confidentiality of your account and password,
            including but not limited to the restriction of access to your computer and/or account.
            You accept responsibility for all activities or actions that occur under your account
            and/or password, whether your password is with our Service or a third-party service. You
            must notify us immediately upon becoming aware of any breach of security or unauthorized
            use of your account.
          </p>{' '}
          <p>
            You may not use as a username the name of another person or entity or that is not
            lawfully available for use, a name or trademark that is subject to any rights of another
            person or entity other than you, without appropriate authorization. You may not use as a
            username any name that is offensive, vulgar or obscene.
          </p>{' '}
          <p>
            We reserve the right to refuse service, terminate accounts, remove or edit content, or
            cancel orders in our sole discretion.
          </p>
          <h3>Intellectual Property</h3>{' '}
          <p>
            The Service and its original content, features and functionality are and will remain the
            exclusive property of Optimize Ventures Inc. and its licensors. The Service is protected
            by copyright, trademark, and other laws of both the United States and foreign countries.
            Our trademarks and trade dress may not be used in connection with any product or service
            without the prior written consent of Optimize Ventures Inc.
          </p>{' '}
          <h3>Links to Other Web Sites</h3>{' '}
          <p>
            Our Service may contain links to third party web sites or services that are not owned or
            controlled by Optimize Ventures Inc. When you link to those sites, you leave the
            Optimize Ventures site. Optimize Ventures Inc. has no control over, and assumes no
            responsibility for the content, resources, privacy policies, or practices of any
            third-party web sites or services. We do not warrant the offerings of any of these
            entities/individuals or their websites. Our Privacy Policy does not apply to the
            practices of any companies or individuals operating the linked sites.
          </p>{' '}
          <p>
            You acknowledge and agree that Optimize Ventures Inc. shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any such content, goods or services
            available on or through any such third party web sites or services.
          </p>{' '}
          <p>
            We strongly advise you to read the terms and conditions and privacy policies of any
            third party web sites or services that you visit. Your use of such third party sites or
            services is at your own risk.
          </p>
          <h3>Termination</h3>
          <p>
            We may terminate or suspend your account and bar access to the Service immediately,
            without prior notice or liability, under our sole discretion, for any reason whatsoever
            and without limitation, including but not limited to a breach of the Terms. We may also
            exercise all other legal and equitable remedies that may be available.
          </p>{' '}
          <p>
            If you wish to terminate your account, you may simply discontinue using the Service.
          </p>{' '}
          <p>
            All provisions of the Terms which by their nature should survive termination shall
            survive termination, including, without limitation, ownership provisions, warranty
            disclaimers, indemnification and limitations of liability.
          </p>
          <h3>Indemnification</h3>{' '}
          <p>
            You agree to defend, indemnify and hold harmless Optimize Ventures Inc. and its licensee
            and licensors, and their employees, contractors, agents, officers and directors, from
            and against any and all claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney's fees), resulting from or
            arising out of a) your use and access of the Service, by you or any person using your
            account and password, or b) a breach of these Terms.
          </p>
          <h3>Limitation Of Liability</h3>
          <p>
            In no event shall Optimize Ventures Inc., nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
            consequential or punitive damages, including without limitation, loss of profits, data,
            use, goodwill, or other intangible losses, resulting from (i) your access to or use of
            or inability to access or use the Service; (ii) any conduct or content of any third
            party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized
            access, use or alteration of your transmissions or content, whether based on warranty,
            contract, tort (including negligence) or any other legal theory, whether or not we have
            been informed of the possibility of such damage, and even if a remedy set forth herein
            is found to have failed its essential purpose.
          </p>{' '}
          <p>
            In no event shall Optimize Ventures’s total liability for all damages exceed the amount
            paid by you for the Service within the one year period prior to the alleged act or
            omission giving rise to Optimize Ventures’s alleged liability.
          </p>{' '}
          <p>
            You agree to bring any and all claims and actions against Optimize Ventures no more than
            one (1) year after the date of the alleged act or omission giving rise to Optimize
            Ventures's liability or be forever barred.
          </p>
          <h3>Disclaimer</h3>
          <p>
            Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and
            "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether
            express or implied, including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement or course of
            performance.
          </p>{' '}
          <p>
            Optimize Ventures Inc. its subsidiaries, affiliates, and its licensors do not warrant
            that a) the Service will function without interruption, be secure or available at any
            time or location; b) any errors or defects will be corrected; c) the Service is free of
            viruses or other harmful components; or d) the results of using the Service will meet
            your requirements.
          </p>{' '}
          <h3>Exclusions</h3>{' '}
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or
            limitation of liability for consequential or incidental damages, so the limitations
            above may not apply to you.
          </p>
          <h3>Governing Law</h3>{' '}
          <p>
            These Terms shall be governed and construed in accordance with the laws of Texas, United
            States, without regard to its conflict of law provisions.
          </p>{' '}
          <p>
            Our failure to enforce any right or provision of these Terms will not be considered a
            waiver of those rights. If any provision of these Terms is held to be invalid or
            unenforceable by a court, the parties nevertheless agree that the court should endeavor
            to give effect to the parties’ intentions as reflected in the provision, and the
            remaining provisions of these Terms will remain in full force and effect. These Terms
            constitute the entire agreement between us regarding our Service, and supersede and
            replace any prior agreements we might have had between us regarding the Service.
          </p>
          <h3>Changes</h3>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any
            time, including by posting information regarding such changes on the Optimize Ventures’s
            website.
          </p>{' '}
          <p>
            By continuing to access or use our Service after any revisions become effective, you
            agree to be bound by the revised terms. If you do not agree to the new terms, you are no
            longer authorized to use the Service. It is your responsibility to monitor these Terms
            and the Optimize Ventures’s website regarding modifications to these Terms.
          </p>
          <h3>Contact Us</h3>{' '}
          <p>
            If you have any questions about these Terms, please contact us at
            info@optimizeventures.com
          </p>
        </div>
      </div>
    </MainLayout>
  )
}
export default Terms
