import { Link } from 'react-router-dom'

interface Props {
  advertorial: any
}

const AdvertorialFooter: React.FC<Props> = ({ advertorial }) => {
  return (
    <div className="mt-20 w-full bg-gray-900 py-10">
      <div className="mx-auto max-w-5xl px-2">
        <div className="mb-6 grid-cols-3 md:grid">
          <div className="col-span-2 text-center md:text-left">
            <a
              href="/"
              className="font-mono text-sm uppercase tracking-tight text-white md:text-xl"
            >
              <span className="font-bold">Penny</span> <span className="-ml-2">Pincher</span>
            </a>
          </div>
          <div>
            <div className="mt-6 grid grid-cols-3 place-items-center text-sm text-gray-200 md:mt-0">
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
        <p className="mt-6 text-sm text-gray-400">
          The article you have just read is an advertisement and not an actual news article, blog,
          or consumer protection update. We are not affiliated with any news source. Our intention
          is to provide an online resource that brings consumers important information that may help
          them make informed decisions.We hope you find our online resource informative and helpful.
          The story told here is for demonstration purposes only and people's results may vary. All
          images used above to depict consumers are used for dramatization purposes only. This
          website and the company that owns it is not responsible for any typographical or
          photographic errors. Any information, discounts or price quotations listed may not be
          applicable in your location or if certain requirements are not met. If you do not agree to
          our terms and policies, then please leave this site immediately.
          <br />
          <br />
          {advertorial && advertorial.sources.length > 0 && (
            <div>
              <div>Sources:</div>
              {advertorial.sources.map((source) => (
                <div key={source.href}>
                  <a className="text-xs" href={source.href} target="_blank">
                    {source.label}
                  </a>
                </div>
              ))}
            </div>
          )}
        </p>
      </div>
    </div>
  )
}

export default AdvertorialFooter
