import { gql } from '@apollo/client'

const GET_ARTICLE = (slug: string | undefined) => gql`
  query {
    articles(filters: { slug: { eq: "${slug}" } }) {
      data {
        id
        attributes {
          title
          slug
          publishedAt
                  author {
          data {
            attributes {
              name
              role
              avatar {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
          display_ads
          preview_text
          content
          primary_image_description
          primary_image {
            data {
              attributes {
                url
              }
            }
          }
          sub_category {
            data {
              id
              attributes {
                name
                articles(pagination: { pageSize: 8 }) {
                  data {
                    id
                    attributes {
                      title
                      slug
                      primary_image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
                href
                category_color
                articles (sort: "id:desc", pagination: {pageSize: 7}) {
                  data {
                    id
                    attributes {
                      title
                      slug
                      primary_image {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_ARTICLE
