interface Props {
  article: any
}

const FeaturedPost: React.FC<Props> = ({ article }) => {
  return article ? (<div></div>
    // <div className="article-container">
    //   <a
    //     className="hover:cursor-pointer"
    //     href={`${
    //       article.featured_article.data.attributes.category?.data.attributes.href || article.href
    //     }/${article.featured_article.data.attributes.slug}`}
    //   >
    //     <div className="grid-cols-5 gap-0 overflow-hidden bg-gradient-to-r from-indigo-500 via-purple-700 to-pink-500 md:grid md:h-72 md:rounded-lg">
    //       <div className="col-span-3 bg-gray-400">
    //         <img
    //           className="h-full w-full object-cover"
    //           src={article.featured_article.data.attributes.primary_image.data.attributes.url}
    //         />
    //       </div>
    //       <div className="col-span-2 bg-white/80 p-2 backdrop-blur-2xl md:p-8">
    //         <div className="headline text-base font-bold leading-tight md:text-lg md:leading-normal lg:text-2xl">
    //           {article.featured_article.data.attributes.title}
    //         </div>
    //         <div className="mt-1 hidden text-xs font-medium md:mt-2 md:block md:text-sm">
    //           <div className="line-clamp-3">
    //             {article.featured_article.data.attributes.preview_text}
    //           </div>
    //         </div>
    //         <div className="mt-2 text-xs font-bold md:text-sm">
    //           {article.featured_article_cta} »
    //         </div>
    //       </div>
    //     </div>
    //   </a>
    // </div>
  ) : (<div></div>
    // <div role="status" className="grid animate-pulse grid-cols-1 gap-8 md:grid-cols-2">
    //   <div
    //     className="flex items-center justify-center rounded bg-gray-300 dark:bg-gray-700"
    //     style={{ aspectRatio: 16 / 9 }}
    //   >
    //     <svg
    //       className="h-12 w-12 text-gray-200"
    //       xmlns="http://www.w3.org/2000/svg"
    //       aria-hidden="true"
    //       fill="currentColor"
    //       viewBox="0 0 640 512"
    //     >
    //       <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
    //     </svg>
    //   </div>
    //   <div className="flex w-full flex-col items-center justify-center">
    //     <div className="mb-4 h-5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
    //     <div className="mb-4 h-5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
    //     <div className="mb-4 h-5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
    //   </div>
    //   <span className="sr-only">Loading...</span>
    // </div>
  )
}

export default FeaturedPost
