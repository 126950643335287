export const validateName = (name: string | undefined) => {
  if (!name || name === '') {
    return 'This field is required'
  }
}

export const validateEmail = (email: string | undefined) => {
  if (!email || email === '') {
    return 'This field is required'
  }

  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return 'Please enter valid email address'
  }
}

export const validatePhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber || phoneNumber === '') {
    return 'This field is required'
  }

  if (phoneNumber.replace(/\D/g, '').length !== 10) {
    return 'Please enter valid phone number'
  }
}

export const validateMessage = (message: string | undefined) => {
  if (!message || message === '') {
    return 'This field is required'
  }
}
