import { Link } from 'react-router-dom'

interface Props {
  article: any
  stories: any
}

const RelatedArticles: React.FC<Props> = ({ article, stories }) => {
  return (
    <div className="mt-10">
      <div className="border-l-[8px] border-orange-600 pl-2 text-base font-medium md:text-xl">
        Related Stories
      </div>
      <div className="mt-4 grid grid-cols-2 gap-6 md:grid-cols-3">
        {article &&
          stories &&
          stories.articles &&
          stories.articles.data.map((item, ind) => (
            <Link
              to={`${stories.href}/${item.attributes.slug}`}
              className={article.title === item.attributes.title ? 'hidden' : ''}
              key={ind}
            >
              <div className="article-container">
                <img
                  className="h-24 w-full rounded-lg object-cover md:h-40"
                  src={item?.attributes?.primary_image.data?.attributes.url}
                />
                <div className="headline mt-1 line-clamp-2 text-sm font-semibold leading-normal tracking-tight md:text-lg">
                  {item.attributes.title}
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  )
}

export default RelatedArticles
