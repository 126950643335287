import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner'
import { apiEmailSignUp } from 'utils/apis'

const EmailSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const handleSignUp = async (): Promise<void> => {
    if (email === '') {
      toast.error('Please input email address')
      return
    }

    try {
      setLoading(true)
      await apiEmailSignUp(email)
      setSubmitted(true)
    } catch (err: any) {
      toast.error(err.message || 'Something went wrong, please try again later')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mx-4 mb-16 mt-6 md:mx-0 md:mt-0 lg:px-2">
      <div className="relative rounded-sm border border-t-[8px] border-indigo-600 shadow-xl">
        {submitted ? (
          <p className="p-6 text-center text-2xl font-bold text-gray-800">Thank you!</p>
        ) : (
          <>
            <div className="p-6 text-center text-xl font-bold text-gray-800 lg:text-2xl">
              Get Exclusive Penny Pinching Tips!
            </div>
            <div className="px-3 text-center text-sm font-medium text-gray-700 lg:text-base">
              Get advice on making, saving and managing your money sent directly to your inbox
              daily.
            </div>
            <div className="mt-6 max-w-md px-4 pb-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="border-1 block w-full min-w-0 rounded-sm bg-white/5 px-3.5 py-3 font-medium text-black ring-inset ring-white/10 shadow-sm ring-1 focus:ring-inset focus:ring-indigo-500 focus:ring-2 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="submit"
                className="mt-2 block w-full rounded-md bg-indigo-600 py-3 text-base font-semibold uppercase text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 lg:text-lg"
                onClick={handleSignUp}
              >
                Sign Me Up!
              </button>
              <div className="mt-4 text-center text-xs font-bold leading-tight tracking-tight text-gray-700">
                By clicking on "SIGN ME UP!", you agree to our{' '}
                <a href="/terms" className="underline">
                  Terms of Use
                </a>{' '}
                &{' '}
                <a href="/privacy" className="underline">
                  Privacy Policy
                </a>
              </div>
            </div>
          </>
        )}
        {loading && (
          <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white/70">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  )
}

export default EmailSignUp
