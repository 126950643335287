import React, { useState, useEffect } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import AdvertorialLayout from '../../layouts/AdvertorialLayout'
import AdvertorialFooter from '../../layouts/AdvertorialFooter'

function CompareRates2() {
  const [lead, setLead] = useState({})
  const [links, setLinks] = useState([])
  const queryParams = queryString.parse(window.location.search)
  const [loading, setLoading] = useState(true)

  const [isReadMoreClicked, setIsReadMoreClicked] = useState(false)

  const handleReadMoreClick = () => {
    setIsReadMoreClicked(true)
  }

  useEffect(() => {
    if (queryParams.ctk) {
      axios
        .get(`https://medigap-reader.telephone.workers.dev/${queryParams.ctk}`)
        .then((result) => {
          const updatedLead = { ...result.data, zipcode: result.data.zip }
          setLead(updatedLead)
        })
        .catch((error) => console.log(error))
    } else {
      getCurrentzipCode()
    }
  }, [queryParams.ctk])

  async function getCurrentzipCode() {
    try {
      const ipAddress = await axios.get('https://api.ipify.org')
      const result = await axios.get(
        `https://api.ipstack.com/${ipAddress.data}?access_key=933580893fcbddecf879a4047c5d4293`
      )
      if (result.data.zip) {
        const updatedLead = {
          ...lead,
          zipCode: result.data.zip,
          city: result.data.city,
          state: result.data.region_name,
        }
        setLead(updatedLead)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (lead.city || lead.state) {
      getData()
    }
  }, [lead])

  async function getData() {
    let leadData = {
      lead: lead,
      publisherToken: queryParams['pub_token'] || '',
      publisherClickId: queryParams['pub_click_id'] || '',
      source: queryParams['source'] || queryParams['utm_source'],
      adInfo: {
        ad_placement_id: queryParams['ad_placement_id'],
        ad_placement_name: queryParams['ad_placement_name'],
        ad_site_name: queryParams['ad_site_name'],
        ad_site_id: queryParams['ad_site_id'],
        ad_campaign_id: queryParams['cid'],
        ad_group_id: queryParams['cid'],
        ad_id: queryParams['aid'],
        utm_source: queryParams['source'],
        utm_medium: queryParams['utm_medium'],
        ad_click_id: queryParams['ad_click_id'],
        voluum_click_id: queryParams['clickid'],
      },
      tracking: {
        t1: queryParams['t1'],
        t2: queryParams['t2'],
        t3: queryParams['t3'],
        t4: queryParams['t4'],
        t5: queryParams['t5'],
      },
      token: '0ce30349-d383-4e9d-8185-b3cf288b889f',
      maxResults: 4,
      landingUrl: lead.lpurl,
      submissionUrl: 'https://' + document.domain,
      userAgent: navigator.userAgent,
    }
    try {
      const result = await axios.post('https://penguin.oapi.me/api/click/get-links', leadData, {
        headers: { 'Content-Type': 'application/json' },
      })
      setLinks(result.data.data)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  return (
    <AdvertorialLayout>
      <div className="mx-auto max-w-3xl px-2">
        <div className="text-xl font-bold md:text-3xl">
          Drivers {lead.state ? 'in ' + lead.state : ''} Are Swapping Their Insurance Company &
          Doing This Instead
        </div>
        <div className="text-lg">
          <p className="mt-4 font-bold text-red-700 md:mt-6">
            Do not pay your next auto insurance bill until you try this…
          </p>
          <p className="mt-4">
            There is 1 simple truth your car insurance company doesn't want you to know. If you are
            currently insured, drive less than 50 miles/day, and live in a qualified zip code. The
            problem is that insurance agents are paid on commission and want to sell you the highest
            possible rate to increase their paycheck.
          </p>
          <p className="mt-4">
            As an authority on everything insurance, we decided to put these services to the test
            and after entering our zip code and driver information we were shocked at the results we
            found..
          </p>
          <p className="mt-4">
            So, what is this <strong>"New Rule"</strong>? NEVER buy insurance without comparing
            rates and discounts from an unbiased source first.
          </p>
          <div className="mt-4 block bg-amber-100 p-4 opacity-80">
            <p className="">
              <strong>Note:</strong> You are <strong>NEVER LOCKED</strong> into your current policy.
              If you've already paid your bill in full, you can very easily cancel, and be refunded
              your balance.
            </p>
          </div>
          <p className="mt-4">
            Once you enter your zip code and go through a few questions, you will have the
            opportunity to compare the best carrier quotes in your area for an average savings of up
            to 32% a month.
          </p>
        </div>

        <div className="mt-8">
          <div className="result">
            <div className="mb-2 text-sm text-gray-800">Sponsored listings</div>

            {loading ? (
              <div>
                <div className="flex items-center rounded-md bg-primary-600 p-4">
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="h-8 fill-white"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                  <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                    <div className="h-5 w-2/3 animate-pulse bg-gray-100 opacity-30"></div>
                  </div>
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-8 fill-white"
                    >
                      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                    </svg>
                  </div>
                </div>
                <div className="mt-3 flex items-center rounded-md bg-primary-600 p-4">
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="h-8 fill-white"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                  <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                    <div className="h-5 w-2/4 animate-pulse bg-gray-100 opacity-30"></div>
                  </div>
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-8 fill-white"
                    >
                      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                    </svg>
                  </div>
                </div>
                <div className="mt-3 flex items-center rounded-md bg-primary-600 p-4">
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="h-8 fill-white"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                  <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                    <div className="h-5 w-1/3 animate-pulse bg-gray-100 opacity-30"></div>
                  </div>
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-8 fill-white"
                    >
                      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                    </svg>
                  </div>
                </div>
                <div className="mt-3 flex items-center rounded-md bg-primary-600 p-4">
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="h-8 fill-white"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                  <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                    <div className="h-5 w-4/5 animate-pulse bg-gray-100 opacity-30"></div>
                  </div>
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-8 fill-white"
                    >
                      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : (
              links.map((item, index) => (
                <a
                  key={index}
                  className={`flex items-center rounded-md bg-primary-600 p-4 ${
                    index === 0 ? '' : 'mt-3'
                  } cursor-pointer hover:bg-primary-700`}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    // Handle cpc_click event
                    // dataLayer.push({ event: 'cpc_click' });
                  }}
                >
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      className="h-8 fill-white"
                    >
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </div>
                  <div className="mx-2 grow align-middle text-lg font-bold leading-7 text-white md:text-xl">
                    {item.headline}
                  </div>
                  <div className="grow-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      className="h-8 fill-white"
                    >
                      <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
                    </svg>
                  </div>
                </a>
              ))
            )}

            {!isReadMoreClicked && (
              <div
                className="readmore mt-10 cursor-pointer text-center font-medium text-primary-600"
                onClick={handleReadMoreClick}
              >
                Read More...
              </div>
            )}
            <div
              className={`extracontent mt-10 text-lg leading-7 ${
                isReadMoreClicked ? '' : 'hidden'
              }`}
            >
              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Maintain a Clean Driving Record
              </h2>
              <p>
                A history of accidents, traffic violations, and reckless driving can significantly
                increase your insurance premiums. By driving safely, obeying traffic rules, and
                avoiding accidents, you can demonstrate to insurance companies that you are a
                low-risk driver, leading to reduced rates. Periodically check your driving record to
                ensure all information is accurate and contest any discrepancies if necessary.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Shop Around and Compare Quotes
              </h2>
              <p>
                Different insurance companies offer different rates based on their own criteria and
                risk assessments. By shopping around and getting quotes from multiple insurers, you
                can compare and choose the most affordable option. Remember to compare similar
                policies in terms of coverage, deductibles, and limits to get a true sense of which
                offers the best value.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Bundle Insurance Policies
              </h2>
              <p>
                Many insurance companies offer discounts when you bundle multiple policies, such as
                auto and home insurance, with the same provider. This not only streamlines your
                billing and policy management but also can lead to significant savings. If you have
                multiple types of insurance needs, inquire about bundling options and discounts.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Increase Your Deductible
              </h2>
              <p>
                Your deductible is the amount you pay out of pocket before your insurance coverage
                kicks in. By agreeing to a higher deductible, you reduce the insurer's risk, which
                often results in a lower premium. However, ensure that you're comfortable with and
                capable of paying the higher deductible in the event of a claim.
              </p>

              <h2 className="mb-2 mt-6 text-xl font-bold text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                  className="-mt-1 mr-2 inline fill-primary-600"
                >
                  <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                </svg>
                Take Advantage of Discounts
              </h2>
              <p>
                Many insurance companies offer various discounts to drivers for factors like low
                mileage, good student achievements, completion of a defensive driving course, having
                safety features in the car, or being a member of certain associations or
                professions. Always inquire about potential discounts and ensure that you're getting
                all the reductions you qualify for.
              </p>
            </div>
          </div>
        </div>
      </div>
      <AdvertorialFooter />
    </AdvertorialLayout>
  )
}

export default CompareRates2
