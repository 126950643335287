import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { Toaster } from 'react-hot-toast'
import { ApolloProvider } from '@apollo/client'
import client from './config/apollo'
import AppRoutes from 'routes'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
}
TagManager.initialize(tagManagerArgs)

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <AppRoutes />
      </Router>
      <Toaster position="top-right" reverseOrder={false} />
    </ApolloProvider>
  )
}
