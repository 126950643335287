import MainLayout from 'layouts/MainLayout'

const DoNotSell = () => {
  window.scrollTo(0, 0)

  return (
    <MainLayout title={'Do Not Sell | Penny Pincher News'}>
      <div className="container mx-auto px-8">
        <div className="prose mx-auto max-w-7xl">
          <h1>California Privacy Rights</h1>
          <p>
            The California Consumer Privacy Act (CCPA) gives California residents certain rights
            regarding personal information given to businesses. One of the rights granted is to
            direct a business to stop selling your personal information. Along with the information
            contained in our privacy policy, we want to provide you a means to specifically state
            your preferences with regard to the selling of your information. To submit a request
            that your data not be sold, please fill out the form on this page.
          </p>
          <div>
            <form action="#" method="POST" className="px-6 pt-10">
              <div className="mx-auto max-w-xl border border-gray-300 p-10 lg:max-w-lg">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="tel"
                        name="phone-number"
                        id="phone-number"
                        autoComplete="tel"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 ring-inset ring-gray-300 shadow-sm ring-1 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-600 focus:ring-2 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex justify-end">
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default DoNotSell
