import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useApolloClient, useQuery } from '@apollo/client'
import MainLayout from 'layouts/MainLayout'
import SideFooter from 'layouts/SideFooter'
import FeaturedPost from 'components/FeaturedPost'
import ArticleFeedVersion2 from 'components/ArticleFeedVersion2'
import AdPlacement300600 from 'components/AdPlacement300600'
import EmailSignUp from 'components/EmailSignUp'
import AdSense from 'components/AdSense'
import GET_CATEGORY from '../../graphql/category_href'

const Category = () => {
  const [articles, setArticles] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const { category } = useParams()
  const client = useApolloClient()
  const { loading, error, data } = useQuery(GET_CATEGORY(`/${category}`, currentPage))

  if (error) return <p>Error :(</p>

  const featuredArticle = data?.categories.data[0].attributes
  const from = data?.categories.data[0].attributes.name
  const textColor = `text-${data?.categories.data[0].attributes.category_color}`

  useEffect(() => {
    setArticles(data?.categories.data[0].attributes.articles.data)

    if (data?.categories.data[0].attributes.articles.data.length < 20) {
      setHasMore(false)
    }
  }, [data])

  const onNextArticles = async (): Promise<void> => {
    if (!articles) return

    const { data } = await client.query({ query: GET_CATEGORY(`/${category}`, currentPage + 1) })

    const temp = articles

    temp.push(...data?.categories.data[0].attributes.articles.data)

    setArticles(temp)

    if (data?.categories.data[0].attributes.articles.data.length < 20) {
      setHasMore(false)
    } else {
      setCurrentPage((prev) => prev + 1)
    }
  }

  return (
    <MainLayout
      title={data?.categories.data[0].attributes.name + ' | Penny Pincher News'}
      description={data?.categories.data[0].attributes.headline}
    >
      <div className="relative isolate -mt-8 overflow-hidden bg-gray-900 md:-mt-10 lg:-mt-20">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/40 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          />
        </svg>
        <div
          className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style={{
              clipPath:
                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pb-10 md:pb-12 lg:px-8 lg:pt-24">
          <div className="text-2xl text-white md:text-5xl">
            {data ? (
              data?.categories.data[0].attributes.name
            ) : (
              <div
                role="status"
                className="my-8 h-5 w-48 animate-pulse rounded-full bg-gray-200 dark:bg-gray-700"
              ></div>
            )}
          </div>
          <div className="mt-2 max-w-3xl text-sm text-gray-400 md:mt-4 md:text-xl">
            {data ? (
              data?.categories.data[0].attributes.headline
            ) : (
              <div
                role="status"
                className="h-3 w-80 animate-pulse rounded-full bg-gray-200 dark:bg-gray-700"
              ></div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4">
        {/*<div className="aspect-h-1 aspect-w-5 mb-0 mt-4 w-full bg-gray-100 px-2 md:mb-6 md:mt-10 md:px-0">*/}
        {/*  <AdSense adClient="ca-pub-3472996490014614" adSlot="9707115732" />*/}
        {/*</div>*/}
        <div className="mt-4 gap-8 md:mt-10 lg:flex">
          <div className="basis-3/4">
            <FeaturedPost article={featuredArticle} />
            {/*<FeaturedThumbnails/>*/}
            <InfiniteScroll
              dataLength={articles?.length || 0}
              next={onNextArticles}
              hasMore={hasMore}
              loader={<p>Loading...</p>}
              endMessage={<></>}
            >
              <ArticleFeedVersion2
                featuredArticle={featuredArticle}
                articles={articles}
                from={from}
                textColor={textColor}
              />
            </InfiniteScroll>
          </div>
          <div className="hidden basis-1/4 lg:block">
            {/*<TrendingArticles/>*/}
            <EmailSignUp />
            <div className="sticky top-36 -mt-8">
              {/*<AdPlacement300600 />*/}
              <SideFooter />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default Category
